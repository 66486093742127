import { defineComponent, reactive, computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import edition from "./components/Edition.vue";
import editionList from "@/assets/data/editionList.json";
import { licenseCategory } from "@/api/license/license";
import { getVersionList } from "@/api/home/home";
import { useStore } from "vuex";
import { getLang } from "@/utils/lang";
import { removeAuth, getToken, setAuth } from "@/utils/auth";
import { getUserInfo } from "@/api/user/user";
export default defineComponent({
  name: "SaleView",
  components: {
    edition
  },

  setup() {
    const router = useRouter();
    let store = useStore();
    let currBtn = 0;

    const changeCurrBtn = function (btnNum) {
      setupData.currBtn = btnNum;
    };

    let vulScanCurrBtn = 0;

    const changeVulScanCurrBtn = function (btnNum) {
      setupData.vulScanCurrBtn = btnNum;
    };

    const downList = computed(() => store.state.downList);

    const init = function () {
      getVersionList().then(res => {
        let newData = [res.data[0], res.data[3], res.data[1], res.data[2]];
        store.commit("setDownList", newData);
      });
      licenseCategory().then(res => {
        store.commit("changeLicenseCategory", res.data);
      });
    }; // 红队版


    let licenseCategory0 = computed(() => store.state.licenseCategory[1]); // 企业版

    let licenseCategory1 = computed(() => store.state.licenseCategory[2]); // 漏扫版

    let vulScanLicenseCategory = computed(() => store.state.licenseCategory[3]);
    onMounted(function () {
      // 首先，获取个人信息
      const token = getToken();

      if (token) {
        Promise.resolve().then(() => {
          getUserInfo({
            index: 1
          }).then(res => {
            if (res.statusCode == 200) {
              setAuth(token, res.data.username, JSON.stringify(res.data));
              setupData.userInfo = computed(() => store.state.userInfo);
            } else {
              removeAuth();
            }
          });
        });
      }

      init();
    });
    const price = computed(() => {
      switch (setupData.currBtn) {
        case 0:
          return Number(setupData.licenseCategory0?.month_amount).toFixed(0);

        case 1:
          return Number(setupData.licenseCategory0?.year_amount).toFixed(0);

        default:
          return 2900;
      }
    });
    const vulScanPrice = computed(() => {
      switch (setupData.vulScanCurrBtn) {
        case 0:
          return Number(setupData.vulScanLicenseCategory?.month_amount).toFixed(0);

        case 1:
          return Number(setupData.vulScanLicenseCategory?.year_amount).toFixed(0);

        default:
          return 2900;
      }
    });

    const trial = function (edition) {
      // router.push({
      //     name: "trial",
      //     params:{
      //         edition
      //     }
      // })
      router.push({
        name: "orderForm",
        params: {
          edition,
          month: setupData.currBtn == 1 ? 12 : 3,
          oneYuan: 1
        }
      });
    };

    const Buy = function (edition) {
      router.push({
        name: "orderForm",
        params: {
          edition,
          month: setupData.currBtn == 1 ? 12 : 3,
          oneYuan: 0
        }
      });
    };

    const chat = function (edition) {
      router.push({
        name: "chat",
        params: {
          edition: edition
        }
      });
    };

    const edition0 = ref(null);
    const edition1 = ref(null);
    const edition2 = ref(null);
    const editionarr = [edition0, edition1, edition2];

    const change = function (keys) {
      let arr = [0, 1, 2];
      arr.filter(item => item != keys).forEach(item => {
        if (editionarr[item].value[0].show) {
          editionarr[item].value[0].show = false;
        }
      });
    };

    const formatPrice = price => {
      const str = price.toString();
      const reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{3})+\.)/g : /(\d)(?=(?:\d{3})+$)/g;
      return str.replace(reg, "$1,");
    };

    let setupData = reactive({
      editionList,
      currBtn,
      vulScanCurrBtn,
      price,
      vulScanPrice,
      changeCurrBtn,
      changeVulScanCurrBtn,
      trial,
      Buy,
      chat,
      licenseCategory0,
      licenseCategory1,
      vulScanLicenseCategory,
      downList,
      change,
      edition0,
      edition1,
      edition2,
      formatPrice,
      currency: getLang() === "en" ? "$" : "￥",
      userInfo: {},
      lang: getLang()
    });
    return setupData;
  }

});