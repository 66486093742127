import "core-js/modules/es.array.includes.js";
import "core-js/modules/web.dom-exception.stack.js";
import { defineComponent, reactive, toRefs, onMounted, nextTick } from 'vue';
import moment from 'moment';
import { getVersions } from '@/api/home/home';
import { getPocPush } from '@/api/poc/poc';
import formatDate from '@/utils/date';
import { i18n } from '@/assets/i18n';
import { getLang } from '@/utils/lang';
const lang = getLang();
export default defineComponent({
  name: 'UpdatesView',

  setup() {
    onMounted(() => {
      getVersionsFn();
    }); // 获取exp相关

    const getVersionsFn = async () => {
      let html = '';
      await getPocPush().then(res => {
        if (res.statusCode === 200) {
          // let html = '';
          let data = res.data || [];
          data.forEach((v, index) => {
            let dateWeek = v.created_at_week;
            let formatDateRes = lang == 'zh' ? dateWeek.split('-')[0] + ' 年第 ' + dateWeek.split('-')[1] + ' 周' : 'Week ' + dateWeek.split('-')[1] + ' of ' + dateWeek.split('-')[0];
            console.log(formatDateRes, "formatDateRes");

            if (index <= 0) {
              html += '<h1 class="h-child h-pbuttom">' + i18n.global.t('PoC') + '</h1>';
              html += '<h2 class="h-child h-pbuttom" style="color:#7586A6;font-size:18px">' + formatDateRes + '</h2>';
            } else {
              html += '<h2 class="h-pbuttom" style="color:#7586A6;font-size:18px">' + formatDateRes + '</h2>';
            }

            let innerData = v.push_data || [];
            innerData.forEach((innerV, indexV) => {
              let stringQuery = innerV.fofa_query.toString();

              if (lang == 'zh') {
                html += `<p class="title-poc"><i class="iconfont iconpoc-01" style="margin-right:8px;color:#F68F66"></i>${innerV.name || '-'}</p> `;
                html += `<table class="loop-table" cellspacing="0" cellpadding="0"> <tr><td>CVE编号</td><td>${innerV.cve_id || '-'}</td><td>CVSS</td><td>${innerV.cvss || '-'}</td></tr>    <tr><td>FOFA Query</td><td><span class="viewFofa" data-fofaQuery="${htmlQuoteEncodeByRegExp(innerV.fofa_query)}">${getFofaText(innerV)}</span> </td><td>受影响资产</td><td>${innerV.asset_count}</td></tr>    <tr><td>漏洞类型</td><td>${innerV.tags}</td><td>更新支持</td><td>${getReleased(innerV.released_at || [])}</td></tr>   <tr><td>描述</td><td colspan="3">${htmlEncodeByRegExp(innerV.description)}</td></tr>   </table>`;
              } else {
                html += `<p class="title-poc"><i class="iconfont iconpoc-01" style="margin-right:8px;color:#F68F66"></i>${innerV.name_en || '-'}</p> `;
                html += `<table class="loop-table" cellspacing="0" cellpadding="0"> <tr><td>CVE</td><td>${innerV.cve_id || '-'}</td><td>CVSS</td><td>${innerV.cvss || '-'}</td></tr>    <tr><td>FOFA Query</td><td><span class="viewFofa" data-fofaQuery="${htmlQuoteEncodeByRegExp(innerV.fofa_query)}">${getFofaText(innerV)}</span></td><td>Affected assets</td><td>${innerV.asset_count}</td></tr>   <tr><td>Type of Vulnerability</td><td>${innerV.tags_en}</td><td>Update Support</td><td>${getReleased(innerV.released_at || [])}</td></tr> <tr><td >Description</td><td colspan="3">${htmlEncodeByRegExp(innerV.description_en)}</td></tr>  </table>`;
              }

              if (innerV.demo_gif_url && innerV.demo_gif_url.includes('<img')) {
                innerV.demo_gif_url = innerV.demo_gif_url.replace(/(<img[^>]*)(>)/ig, function (match, p1, p2) {
                  return p1 + ' loading="lazy"' + p2;
                });
                html += innerV.demo_gif_url;
                console.log(innerV.demo_gif_url, "innerV.demo_gif_url");
              } else if (expModule.isURL(innerV.demo_gif_url)) {
                html += `<img src="${innerV.demo_gif_url}" loading="lazy"/>`;
              }

              html = html.replace(/(<img[^>]* )(alt\s*=\s*['"][^'"]*['"])([^>]*>)/ig, "$1$3");
            });
          });
        }
      });
      getVersions().then(res => {
        if (res.statusCode === 200) {
          // let html = '';
          let data = res.data || [];
          data.forEach((v, index) => {
            let formatDateRes = formatDate(v.created_at);

            if (index <= 0) {
              html += '<h1 class="h-child h-pbuttom">' + i18n.global.t('Historical version') + '</h1>';
              html += '<h2 class="h-child h-pbuttom">' + v.version_base + ' (' + v.version + ') • ' + formatDateRes + '</h2>';
            } else {
              html += '<h2 class="h-pbuttom">' + v.version_base + ' (' + v.version + ') • ' + formatDateRes + '</h2>';
            }

            html += '<p style="margin-bottom:30px">Downloads:&nbsp;<a href="' + v.url + '" style="text-decoration:underline;">Windows</a>&nbsp;&nbsp;';
            html += '<a href="' + v.mac_download_url + '" style="text-decoration:underline;">MacOS</a>&nbsp;&nbsp;';
            html += '<a href="' + v.linux_download_url + '" style="text-decoration:underline;">Linux</a></p>';
            v.content = (v.content || '').replace(/(<img[^>]*)(>)/ig, function (match, p1, p2) {
              return p1 + ' loading="lazy"' + p2;
            });
            html += (v.content || '').replace(/(<img[^>]* )(alt\s*=\s*['"][^'"]*['"])([^>]*>)/ig, "$1$3");
          });
          $("#faq .faq-right").empty();
          $("#toc").empty();
          $("#faq .faq-right").append(html);
          nextTick(() => {
            $("#toc").tocify({
              selectors: "h1,h2,h3,h4,h5,h6",
              hideEffect: 'slideUp'
            });
            $(document).on("click", ".viewFofa", function (e) {
              if (e.target && e.target.dataset.fofaquery) {
                let str = e.target.dataset.fofaquery;
                str = window.btoa(unescape(encodeURIComponent(str)));
                str = window.encodeURIComponent(str); //  console.log(`https://fofa.info/result?qbase64=${str}`,"fofaUrl111111111");

                window.open(`https://fofa.info/result?qbase64=${str}`);
              }
            });
          });
        }
      }).catch(err => {
        console.log(err);
        console.log(1);
      });
    };

    const isURL = s => {
      return /^http[s]?:\/\/.*/.test(s);
    };

    const htmlQuoteEncodeByRegExp = str => {
      let s = '';

      if (str.length === 0) {
        return '';
      }

      s = str.replace(/"/g, '&quot;');
      return s;
    };

    const getReleased = release => {
      let arr = release.map(item => expModule.lang == 'zh' ? item && item.name : item && item.name_en);
      return arr.join('、') || '-';
    };

    const getFofaText = innerV => {
      if (innerV.product) {
        return `app="${innerV.product}"`;
      } else {
        return expModule.htmlEncodeByRegExp(innerV.fofa_query);
      }
    };

    const htmlEncodeByRegExp = str => {
      let s = '';

      if (str.length === 0) {
        return '';
      }

      s = str.replace(/&/g, '&amp;');
      s = s.replace(/</g, '&lt;');
      s = s.replace(/>/g, '&gt;');
      s = s.replace(/ /g, '&nbsp;');
      s = s.replace(/'/g, '&#39;');
      s = s.replace(/"/g, '&quot;');
      return s;
    };

    let expModule = reactive({
      lang,
      htmlText: "",
      getVersions,
      htmlEncodeByRegExp,
      htmlQuoteEncodeByRegExp,
      moment: moment,
      getFofaText,
      getReleased,
      isURL
    });
    return { ...toRefs(expModule)
    };
  }

});