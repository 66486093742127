import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Request } from '@/api/request'
import { RequestGama } from '@/api/requestGama'
import { lazyPlugin } from '@/directives'


// 引入i18n翻译
import { i18n } from '@/assets/i18n'

// 引入element-plus css 如不引入否则css会出问题 比如message
import 'element-plus/dist/index.css'
import './assets/css/elementPlus.scss'

// 引入css文件
import './assets/css/style.scss'

// 引入字体文件
import './assets/fonts/iconfont.css' 
import './assets/font1/iconfont.css'





createApp(App).use(i18n).use(store).use(router).use(lazyPlugin).mount('#app')


// axios初始化
Request.init()
RequestGama.init()
